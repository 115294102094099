import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Loader from "./components/Loader";
import {ToastContainer} from "react-toastify";

const Header= React.lazy(() => import("./components/Header"));
const Home = React.lazy(() => import("./pages/Home"));
const PhotoBooks = React.lazy(() => import("./pages/PhotoBooks"));
const Newborn = React.lazy(() => import("./pages/Newborn"));
const Children = React.lazy(() => import("./pages/Children"));
const CakeSmash = React.lazy(() => import("./pages/CakeSmash"));
const Reviews = React.lazy(() => import("./pages/Reviews"));
const Certificates = React.lazy(() => import("./pages/Certificates"));
const GalleriesList = React.lazy(() => import("./pages/GalleriesList"));
const Gallery = React.lazy(() => import("./pages/Gallery"));
const Pages = React.lazy(() => import("./pages/Pages"));
const BackToTopButton = React.lazy(() => import("./components/BackToTopButton"));
const Error500 = React.lazy(() => import("./pages/Error500"));
const NotFound404 = React.lazy(() => import("./pages/NotFound404"));
const Footer = React.lazy(() => import("./components/Footer"));

function App() {

  return (
    <>
        <BrowserRouter>
            <React.Suspense fallback={<Loader/>}>
                    <Header />
                <div className="main">
                    <Switch>
                        <Route exact path='/'>
                            <Home />
                        </Route>
                        <Route exact path='/newborn-kyiv-photo/:newborn_slug/'>
                            <Newborn />
                        </Route>
                        <Route exact path='/newborn/'>
                            <Newborn />
                        </Route>
                        <Route exact path='/babies-photo/'>
                            <Children />
                        </Route>
                        <Route exact path='/cake-smash-photosession/'>
                            <CakeSmash />
                        </Route>
                        <Route exact path='/certificates/'>
                            <Certificates />
                        </Route>
                        <Route exact path='/photobooks-kyiv/'>
                            <PhotoBooks />
                        </Route>
                        <Route exact path='/newborn-photographer-reviews/'>
                            <Reviews />
                        </Route>
                        <Route exact path='/pages/:slug/'>
                            <Pages />
                        </Route>
                        <Route exact path='/photo-galleries/'>
                            <GalleriesList />
                        </Route>
                        <Route exact path='/photo-galleries/:gallery_slug/'>
                            <Gallery />
                        </Route>
                        <Route exact path='/404'>
                            <NotFound404 />
                        </Route>
                        <Route exact path='/500'>
                            <Error500 />
                        </Route>
                        <Route path="/admin" render={() => window.location = "https://wonder.kiev.ua/auth/login/"} />
                        <Redirect to="/404" />
                    </Switch>
                    <BackToTopButton />
                </div>
            </React.Suspense>
            <ToastContainer />
            <Footer />
        </BrowserRouter>
    </>
  );
}

export default App;
